import styled from 'styled-components';

import colors from '../../../config/colors';

export default function (UnStyledTableBody) {
    return styled(UnStyledTableBody)`
        margin-top: 1.5rem;

        > table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;

            > thead {
                > tr {
                    vertical-align: top;

                    > th {
                        font-weight: 650;
                        color: ${colors.white};
                        padding: 0.9rem 0.6rem;
                        background-color: ${colors.primary};
                        border-right: 1px solid ${colors.white};

                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            &.actions {
                                justify-content: center;
                            }

                            > span {
                                display: inline-block;
                                margin-right: 0.5rem;
                            }
                        }
                    }

                    > th:first-child {
                        border-radius: 15px 0 0 0;
                    }

                    > th:last-child {
                        border-radius: 0 15px 0 0;
                    }
                }
            }

            > tbody {
                > tr {
                    vertical-align: baseline;
                    background: white;
                    border-top: 3px solid #e4e7f2;
                    > td {
                        font-weight: 550;
                        padding: 0.6rem 0.6rem 0.55rem 0.6rem;
                        vertical-align: middle;
                    }
                }

                > tr:hover {
                    background: #f6f8fa;
                    cursor: pointer;
                }
            }
        }
    `;
}
